import * as React from "react";

//import "../assets/plugins/nucleo/css/nucleo.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";
import "../assets/scss/argon-dashboard-react.scss";
import PrimaryNavbar from "../components/Navbars/PrimaryNavbar";

import { Button, Col, Container, Row, Badge } from "reactstrap";
import { Helmet } from "react-helmet";

import appleIcon from "../assets/img/bailey/apple-touch-icon.png";
import fav16 from "../assets/img/bailey/favicon-16x16.png";
import fav32 from "../assets/img/bailey/favicon-32x32.png";
import baileyHeadshot from "../assets/img/bailey/bailey.png";
import safariPinned from "../assets/img/bailey/safari-pinned-tab.svg";
import API from "../lib/API";

/*if (window.location.href.indexOf("localhost") >= 0) {
  accountManagerPath = "http://localhost:3000";
}*/

let everyplate = require("../assets/img/bailey/everyplate_logo.png");
let greenchef = require("../assets/img/bailey/greenchef.png");
let hellofresh = require("../assets/img/bailey/hellofresh_logo.png");
let facebook = require("../assets/img/bailey/facebook_logo.png");
let instagram = require("../assets/img/bailey/ig_logo.jpeg");
let tiktok = require("../assets/img/bailey/tiktok_logo.png");
let squatch = require("../assets/img/bailey/dr_squatch_logo.png");

// markup
class IndexPage extends React.Component {
  state = {
    email: "",
    zip: "",
    leadSource: "",
    isDesktop: false,
    phone: {
      value: "",
    },
    ffRequirePhone: true,
    portfolio: null,
    portfolioIndex: 0,
    portfolioOptions: [
      {
        brandLogo: greenchef,
        campaignName: `"3 Myths" Recipe Highlight`,
        campaignDescription:
          "Created a top-performing recipe highlight mashup and blended a high conversion sales sequence into the educational through-line.",
        bullets: [
          "Acting on learnings from engagement data on recipe launch videos, this mashup was produced to strategically boost retention metrics to ease friction-inducing educational copy.",
          "Utilizing the 3 signs sales sequence further lightened the heavier copy structure due to the viewer being prompted with multiple off-ramps that yielded a CTR and CVR increase.",
        ],
        type: "video",
        platforms: [facebook, instagram, tiktok],
        url:
          "https://s3.amazonaws.com/cdn.projectleannation.com/baileygribbencom/GreenChef-3MythsRecipeHighlight.mp4",
      },

      {
        brandLogo: greenchef,
        campaignName: `"Balanced Living" Evergreen Concept`,
        campaignDescription:
          "Educational structure to launch a new product category for the client’s subscribers.",
        bullets: [
          "Crafted a feature/benefit sales sequence with a narrative through-line based on common lifestyle issues I found consumers in the target demographic reported through consumer research.",
          "Feature/benefit pairs are structured such that the most relevant pairing is within the first 15 seconds of the video, allowing the retention metric to serve as a viability gauge for the perceived top feature/benefit.",
          "Blending user-testimonials and lower-third CTA graphics in between each feature/benefit creates a predictable pace for viewers, and offers clear off-ramps for educated viewers to click out and convert.",
          "Collaborated with Food Stylists and Production to identify meals that presented the best variety of ingredients while also set dressing to look casual and approachable.",
        ],
        type: "video",
        platforms: [facebook, instagram, tiktok],
        url:
          "https://s3.amazonaws.com/cdn.projectleannation.com/baileygribbencom/GreenChef-BalancedLivingEvergreen.mp4",
      },
      {
        brandLogo: hellofresh,
        campaignName: "Cook With Me",
        campaignDescription:
          "Leveraged industry standard top-down cooking video styles to personalize the user experience for the client’s service and break the cost/value barrier to increase overall performance.",
        bullets: [
          "Leaned into a conversational copy structure and narrative tone to amplify the first-person POV visual style, creating a video that feels personalized for each individual viewer.",
          "Strategically chose the client’s highest-end recipe to further reduce cost/value friction, and supported that sentiment with user testimonials about product quality and value compared to industry competitors.",
        ],
        type: "video",
        platforms: [facebook, instagram, tiktok],
        url:
          "https://s3.amazonaws.com/cdn.projectleannation.com/baileygribbencom/HelloFresh-CookWithMe.mp4",
      },
      {
        brandLogo: squatch,
        campaignName: `"Split-Screen" Scent`,
        campaignDescription:
          "Utilized cross-platform engagement performance to identify the top-performing on-ramp visual style and combine it with another top-converting sales sequence for the base of the video.",
        bullets: [
          "Adapted the split-screen opening visual style that was a consistent thumbstop top-performer on TIkTok and brought it into the Meta platform. Reduced performance risk by leveraging a historic CVR top-performing scent focused sales-sequence and listicle structure.",
          "Leveraged UGC reaction shots to further amplify the scent-focused sales sequence and reinforce product quality claims.",
        ],
        type: "video",
        platforms: [facebook, instagram, tiktok],
        url:
          "https://s3.amazonaws.com/cdn.projectleannation.com/baileygribbencom/SplitScreenScent.mp4",
      },

      {
        brandLogo: squatch,
        campaignName: "Avoid Flakey Skin",
        campaignDescription:
          "Consumer research showed that combating dry skin was the top problem the client’s product solved, second to the product’s scent. This narrative was crafted to visualize the difference our product makes in solving that problem compared to industry alternatives.",
        bullets: [
          "Focused on a visual narrative over the copy narrative in order to highlight the tangible qualitative differences between the client’s product and common industry alternatives.",
          "Utilized hyper-dramatic footage to increase engagement metrics while using a first-person testimonial based copy structure to increase trust with the viewer, yielding higher CTR and CVR.",
        ],
        type: "video",
        platforms: [facebook, instagram, tiktok],
        url:
          "https://s3.amazonaws.com/cdn.projectleannation.com/baileygribbencom/AvoidFlakeySkin.mp4",
      },
      {
        brandLogo: require("../assets/img/bailey/gribbengroup.png"),
        campaignName: "The Revelry: Rev, White, & Blue",
        campaignDescription:
          "Worked with The Revelry restaurant to help produce a high-energy, high-engagement video to promote their 11th annual BBQ event using aerial and ground footage.",
        bullets: [
          "Provided created guidance for multi-angle drone & ground footage.",
          "Operated drone for aerial footage during fireworks display.",
          "Resulted in highest reel engagement rate in client history.",
        ],
        type: "video",
        platforms: [facebook, instagram],
        url:
          "https://s3.amazonaws.com/cdn.projectleannation.com/baileygribbencom/RPReplay_Final1720108091.mov",
      },

      /*{
        brandLogo: squatch,
        campaignName: "Campaign Name",
        campaignDescription: "Desc",
        bullets: ["One"],
        type: "video",
        platforms: [facebook, instagram, tiktok],
        url:
          "https://s3.amazonaws.com/cdn.projectleannation.com/baileygribbencom/CologneHeroTHB.mp4",
      },
      {
        brandLogo: squatch,
        campaignName: "Campaign Name",
        campaignDescription: "Desc",
        bullets: ["One"],
        type: "video",
        platforms: [facebook, instagram, tiktok],
        url:
          "https://s3.amazonaws.com/cdn.projectleannation.com/baileygribbencom/NaturalSoapEvergreen.mp4",
      },

      {
        brandLogo: squatch,
        campaignName: "Campaign Name",
        campaignDescription: "Desc",
        bullets: ["One"],
        type: "video",
        platforms: [facebook, instagram, tiktok],
        url:
          "https://s3.amazonaws.com/cdn.projectleannation.com/baileygribbencom/StopCheapingOut.mp4",
      },
      {
        brandLogo: squatch,
        campaignName: "Campaign Name",
        campaignDescription: "Desc",
        bullets: ["One"],
        type: "image",
        platforms: [facebook, instagram],
        url:
          "https://s3.amazonaws.com/cdn.projectleannation.com/baileygribbencom/PresDayStill.jpeg",
      },*/
    ],
  };

  handleResize(mq) {
    let isDesktop = mq && mq.matches ? true : false;

    this.setState({ isDesktop });
  }

  componentWillUnmount() {}

  setPortfolioIndex(increment) {
    let idx = this.state.portfolioIndex;

    if (idx + increment < 0) {
      idx = this.state.portfolioOptions?.length - 1;
    } else if (idx + increment > this.state.portfolioOptions?.length - 1) {
      idx = 0;
    } else {
      idx = idx + increment;
    }

    this.setState({
      portfolioIndex: idx,
      portfolio: this.state.portfolioOptions[idx],
    });
  }

  componentDidMount() {
    const desktopMediaQuery = window.matchMedia("(min-width: 768px)");
    const isDesktop = desktopMediaQuery.matches ? true : false;

    let scrollpos = window.scrollY;
    const header = document.querySelector("body");
    const header_height = 20;

    const add_class_on_scroll = () => header.classList.add("body-scroll");
    const remove_class_on_scroll = () => header.classList.remove("body-scroll");

    this.setState({
      portfolio: this.state.portfolioOptions[0],
    });

    window.addEventListener("scroll", function () {
      scrollpos = window.scrollY;

      if (scrollpos >= header_height) {
        add_class_on_scroll();
      } else {
        remove_class_on_scroll();
      }
    });
  }

  renderVideo(portfolio) {
    if (!portfolio) {
      return null;
    }

    if (portfolio?.type == "video" && portfolio?.url) {
      return (
        <video
          preload="metadata"
          width={"100%"}
          key={portfolio?.url}
          style={{ width: "100%" }}
          height="400"
          controls
        >
          <source src={portfolio?.url + "#t=0.1"} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Bailey Gribben - Creative Strategist</title>
          <meta
            name="description"
            content="As a Creative Strategist, I specialize in scaling and optimizing marketing campaigns for efficient ROAS and high engagement, drawing upon my expertise in paid media, e-commerce development, and multimedia production."
          ></meta>
          <meta property="og:locale" content="en_US"></meta>
          <meta property="og:type" content="website"></meta>
          <meta
            property="og:title"
            content="Bailey Gribben - Creative Strategist"
          ></meta>
          <meta
            property="og:description"
            content="As a Creative Strategist, I specialize in scaling and optimizing marketing campaigns for efficient ROAS and high engagement, drawing upon my expertise in paid media, e-commerce development, and multimedia production."
          ></meta>
          <meta property="og:url" content="https://baileygribben.com/"></meta>
          <meta property="og:site_name" content="Bailey Gribben"></meta>
          <meta
            name="image"
            property="og:image"
            content={require("../assets/img/bailey/gribben_seo_primary_banner.jpg")}
          ></meta>
          <meta property="og:image:width" content="1024"></meta>
          <meta property="og:image:height" content="768"></meta>
          <link rel="apple-touch-icon" sizes="180x180" href={appleIcon} />
          <link rel="icon" type="image/png" sizes="32x32" href={fav32} />
          <link rel="icon" type="image/png" sizes="16x16" href={fav16} />
          <link rel="mask-icon" href={safariPinned} color="#38b6cd" />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta name="theme-color" content="#ffffff"></meta>
        </Helmet>
        <PrimaryNavbar></PrimaryNavbar>
        <div className="mt-5 mt-lg-6" id="about"></div>
        <div className="bg-white" style={{ maxWidth: 1000, margin: "auto" }}>
          <div className="py-4 py-md-5 py-lg-5 px-1 px-md-4">
            <Container fluid>
              <div className="">
                <Row className="align-items-center">
                  <Col xs="12" sm="12" lg="12">
                    <Row className="align-items-center mb-2 mb-sm-5">
                      <Col xs="12" sm="auto" className="order-sm-1">
                        <img
                          src={baileyHeadshot}
                          className="mb-4 mb-sm-0 border shadow bailey-headshot"
                        ></img>
                      </Col>
                      <Col xs="12" sm="" className="order-sm-0">
                        <h1
                          className="display-1 text-capitalize text-dark text-left m-0"
                          style={{ lineHeight: 1.2 }}
                        >
                          Hello, I’m <br></br> Bailey Gribben.
                        </h1>
                      </Col>
                    </Row>

                    <p className="lead">
                      I’m a passionate Marketing Strategist with experience in
                      DTC and B2C marketing strategy, leading clients and
                      companies to grow their market position while optimizing
                      multi-million dollar budgets with ROAS ~260%.
                    </p>
                    <p className="lead">
                      I’m currently seeking full-time opportunities in
                      Rochester, NY or remote.
                    </p>
                    <div className="mt-5">
                      <h2 className="text-dark mb-4" id="brands">
                        Brands &amp; Companies I've Performed Work For:
                      </h2>
                    </div>
                    <div style={{ overflowX: "hidden" }} className="pt-2">
                      <Row>
                        <Col
                          xs="12"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/bailey/gribbengroup.png")}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/bailey/articlegroup.png")}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/bailey/cypressnorth.png")}
                            style={{ height: 55, maxWidth: "100%" }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={squatch}
                            style={{ height: 55, maxWidth: "100%" }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={everyplate}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={require("../assets/img/bailey/firstmedia_logo.png")}
                            style={{ height: 55, maxWidth: "100%" }}
                            alt=""
                          ></img>
                        </Col>
                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={hellofresh}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>

                        <Col
                          xs="6"
                          sm="auto"
                          className="mb-4 text-center text-sm-left"
                        >
                          <img
                            src={greenchef}
                            style={{ height: 55 }}
                            alt=""
                          ></img>
                        </Col>
                      </Row>
                    </div>

                    <div className="mt-4">
                      <h2 className="text-dark mb-4">Skills:</h2>
                    </div>
                    <div>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        DTC Product Marketing
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Inbound/Outbound Strategy
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Content Strategy
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Paid Campaign Management
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Media Buying
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Performance Marketing
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        SEO / CRO
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Meta Ads Manager
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Google Analytics
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        SEMRush
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Adobe Creative Cloud
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Google BigQuery
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Project Management
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Site Plan & Map Development
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Qualitative Market Research
                      </Badge>
                      <Badge
                        color="primary"
                        className="rounded mr-3 mb-3"
                        style={{
                          fontSize: 15,
                          color: "var(--primary-dark)",
                          border: "1px solid var(--primary)",
                        }}
                      >
                        Copywriting/Script Writing
                      </Badge>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
        </div>
        <div
          className="bg-primary-ultralight mb-5 mb-lg-6"
          style={{
            borderTop: "1px solid var(--primary-superlight)",
            borderBottom: "1px solid var(--primary-superlight)",
          }}
        >
          <div style={{ maxWidth: 1000, margin: "auto" }}>
            <div className="px-1 px-md-4">
              <Container fluid>
                <div className="py-4 py-lg-5">
                  <h1
                    className="text-dark mt-0 mt-md-2 mt-lg-0 mb-2 display-4"
                    id="portfolio"
                  >
                    Data-Driven Creative That Gets Results &amp; Drives Growth
                  </h1>
                  <p className="text-medium mb-0 font-weight-400">
                    Here's some of my latest creative strategy &amp; performance
                    marketing work.
                  </p>
                  <div className="rounded bg-white px-4 py-4 border shadow mt-4 mt-md-5">
                    <Row className="align-items-center">
                      <Col xs="12" sm="7" md="8">
                        <img
                          src={this.state.portfolio?.brandLogo}
                          style={{ height: 50 }}
                        ></img>
                        <h2 className="text-dark mt-3">
                          {this.state.portfolio?.campaignName}
                        </h2>
                        <p className="text-medium mb-2">
                          {this.state.portfolio?.campaignDescription}
                        </p>
                        <h4 className="text-dark mt-3 mb-3">Platforms</h4>
                        <div>
                          {this.state.portfolio?.platforms?.map(
                            (platform, i) => (
                              <img
                                src={platform}
                                style={{ height: 30 }}
                                height="30"
                                width="30"
                                className="mb-2 mr-2"
                                key={i}
                              ></img>
                            )
                          )}
                        </div>
                        <h4 className="text-dark mt-3">Highlights</h4>
                        <ul
                          className="text-medium font-weight-400 mt-0 pt-1"
                          style={{ paddingLeft: "2rem" }}
                        >
                          {this.state.portfolio?.bullets?.map((bullet, i) => (
                            <li
                              key={i}
                              style={{
                                paddingTop: "0.3rem",
                                paddingBottom: "0.3rem",
                              }}
                            >
                              {bullet}
                            </li>
                          ))}
                        </ul>
                      </Col>
                      <Col xs="12" sm="5" md="4" className="mt-3 mt-sm-0">
                        {this.state.portfolio?.type == "image" ? (
                          <>
                            <div className="text-center text-sm-right">
                              <img
                                src={this.state.portfolio?.url}
                                style={{
                                  width: "100%",
                                  maxWidth: 250,
                                  margin: "auto",
                                }}
                                alt={this.state.portfolio?.campaignName}
                              ></img>
                            </div>
                          </>
                        ) : (
                          <>{this.renderVideo(this.state.portfolio)}</>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="mt-4 d-flex justify-content-center">
                    <Row className="align-items-center">
                      <Col xs="auto">
                        <Button
                          className="btn-icon-only"
                          color="primary"
                          onClick={() => {
                            this.setPortfolioIndex(-1);
                          }}
                        >
                          <svg viewBox="0 0 24 24" fill="#FFFFFF">
                            <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                          </svg>
                        </Button>
                      </Col>
                      <Col xs="auto">
                        {this.state.portfolioIndex + 1} of{" "}
                        {this.state.portfolioOptions?.length}
                      </Col>
                      <Col xs="auto">
                        <Button
                          className="btn-icon-only"
                          color="primary"
                          onClick={() => {
                            this.setPortfolioIndex(1);
                          }}
                        >
                          <svg viewBox="0 0 24 24" fill="#FFFFFF">
                            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                          </svg>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Container>
            </div>
          </div>
        </div>
        <div id="education"></div>
        <div className="bg-white" style={{ maxWidth: 1000, margin: "auto" }}>
          <div className=" px-1 px-md-4">
            <Container fluid>
              <div className="mt-5">
                <h1 className="text-dark mt-0 display-4 mb-4">Education</h1>
                <h2 className="text-dark mb-1">B.S. Applied Arts & Sciences</h2>
                <p className="small text-medium mb-2">
                  Rochester Institute of Technology
                </p>
              </div>
              <div className="mt-5">
                <div>
                  <Row className="align-items-center  mb-4">
                    <Col xs="">
                      <h1 className="text-dark m-0 display-4">Experience</h1>
                    </Col>
                    <Col xs="auto">
                      <a
                        href="https://s3.amazonaws.com/cdn.projectleannation.com/baileygribbencom/Bailey+Gribben+Resume+(1).pdf"
                        target="_blank"
                      >
                        <Button color="primary" size="sm">
                          Download Resume
                        </Button>
                      </a>
                    </Col>
                  </Row>
                  <div>
                    <h2 className="text-dark mb-1">
                      Founder, Chief of Strategy
                    </h2>
                    <p className="small text-medium mb-2">
                      Gribben Group in Rochester, NY [2023-Present]
                    </p>
                    <ul
                      className="text-dark mt-0 pt-1"
                      style={{ paddingLeft: "2rem" }}
                    >
                      <li>
                        Driving significant e-commerce, enterprise SaaS product
                        positioning, and brand market growth for DTC and B2B
                        clients, including Fortune 100/500 companies and key
                        players in franchising.
                      </li>
                      <li>
                        Partnering with clients’ CMOs and Strategy Directors to
                        refine marketing strategies and enhance campaign
                        effectiveness.
                      </li>
                      <li>
                        Launching inbound and outbound marketing efforts for
                        CTOs and Directors of Marketing Automation, enhancing
                        client presence through email campaigns, social media
                        engagement, and SEO web content.
                      </li>
                    </ul>
                  </div>
                  <hr className="my-3"></hr>
                  <div>
                    <h2 className="text-dark mb-1">
                      Digital Marketing Strategist
                    </h2>
                    <p className="small text-medium mb-2">
                      Cypress North in Rochester, NY [2024]
                    </p>
                    <ul
                      className="text-dark mt-0 pt-1"
                      style={{ paddingLeft: "2rem" }}
                    >
                      <li>
                        Led the introduction of new paid marketing channels,
                        enhancing agency offerings and driving client success.
                      </li>
                      <li>
                        Utilized tools such as Google Analytics 4, SEMRush,
                        Google Search Console, Meta Ads Manager, and Looker
                        Studio to deliver data-driven results in dynamic
                        reporting dashboards.
                      </li>
                      <li>
                        Developed site plans and maps for SEO and CRO
                        improvements across non-profit, enterprise manufacturing
                        B2B, and B2C sectors.
                      </li>
                      <li>
                        Built custom landing pages to increase CRO for B2C
                        clients providing financial and recruiting services.
                      </li>
                      <li>
                        Conducted rolling performance audits of ad campaigns,
                        optimizing assets to address issues and boost
                        high-performing elements.
                      </li>
                    </ul>
                  </div>
                  <hr className="my-3"></hr>
                  <div>
                    <h2 className="text-dark mb-1">
                      Creative Strategist, Growth Marketing
                    </h2>
                    <p className="small text-medium mb-2">
                      Dr. Squatch in Marina Del Ray, CA (Remote) [2022-2023]
                    </p>
                    <ul
                      className="text-dark mt-0 pt-1"
                      style={{ paddingLeft: "2rem" }}
                    >
                      <li>
                        Led creative strategy for domestic and international
                        Meta platforms.
                      </li>
                      <li>Contributed to creative strategy for TikTok.</li>
                      <li>
                        Contributed to content strategy for e-commerce platforms
                        like Amazon PDP/search and email.
                      </li>
                      <li>
                        Produced and launched scalable international creative
                        strategy plans for Meta & TikTok platforms with Paid
                        Media colleagues across all emerging markets.
                      </li>
                      <li>
                        Oversaw and executed content strategy for all marketing
                        promotions and new product launches, achieving a
                        sub-$100 avg. CPA.
                      </li>
                    </ul>
                  </div>
                  <hr className="my-3"></hr>
                  <div>
                    <h2 className="text-dark mb-1">
                      Creative Strategist, Performance Marketing
                    </h2>
                    <p className="small text-medium mb-2">
                      First Media US in Los Angeles, CA (Remote) [2021-2022]
                    </p>
                    <ul
                      className="text-dark mt-0 pt-1"
                      style={{ paddingLeft: "2rem" }}
                    >
                      <li>
                        Strategized creative for all domestic and international
                        food accounts in the Performance Marketing division.
                      </li>
                      <li>
                        Analyzed account performance and created strategic
                        production plans to achieve CPA, CVR, and engagement
                        KPIs.
                      </li>
                      <li>
                        Wrote scripts to conceptualize new creative or amplify
                        top-performing adsets while expanding into broader
                        prospective markets.
                      </li>
                      <li>
                        Produced videos in collaboration with Editors, Jr. & Sr.
                        Creative Strategists, and Food Stylists.
                      </li>
                      <li>
                        Performed qualitative market and competitor research to
                        form a new client informational core pack during
                        onboarding.
                      </li>
                    </ul>
                  </div>
                  <hr className="my-4"></hr>
                  <div>
                    <h2 className="text-dark mb-1">Junior Brand Strategist</h2>
                    <p className="small text-medium mb-2">
                      Optic Sky Productions in Rochester, NY [2018-2021]
                    </p>
                    <ul
                      className="text-dark mt-0 pt-1 mb-0"
                      style={{ paddingLeft: "2rem" }}
                    >
                      <li>
                        Conducted iterative brand strategy reviews for all
                        clients throughout the business development process.
                      </li>
                      <li>
                        Wrote, produced, and directed case study videos to
                        present each project and their performance marketing
                        metrics.
                      </li>
                      <li>
                        Copywrote for website pages, PR, and industry
                        publications.
                      </li>
                      <li>
                        Created and directed lead-gen strategy for various
                        sectors to implement the division‘s first CRM solution.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-5 mb-5">
                <div>
                  <h1
                    className="text-dark mt-0 mt-md-2 mt-lg-0 mb-4 display-4"
                    id="certifications"
                  >
                    Certifications
                  </h1>
                </div>
                <div>
                  <img
                    src={require("../assets/img/bailey/google-analytics-cert.png")}
                    alt="ga cert"
                    style={{ height: 50 }}
                    className="mr-4 mb-3"
                  ></img>
                  <img
                    src={require("../assets/img/bailey/google-ads-cert.png")}
                    alt="ga cert"
                    style={{ height: 50 }}
                    className="mr-4 mb-3"
                  ></img>
                  <img
                    src={require("../assets/img/bailey/snapchat-cert.png")}
                    alt="ga cert"
                    style={{ height: 70 }}
                    className="mr-4 mb-3"
                  ></img>
                  <img
                    src={require("../assets/img/bailey/hubspotseo.png")}
                    alt="ga cert"
                    style={{ height: 70 }}
                    className="mr-4 mb-3"
                  ></img>
                </div>
              </div>
              <div className="mt-5 mb-5" id="contact">
                <div className="bg-primary px-4 py-4 rounded shadow">
                  <Row className="align-items-center">
                    <Col xs="12" sm="">
                      <h1 className="text-white mt-0  mb-1 display-4">
                        Reach Out
                      </h1>
                      <p className="text-white mb-0 mt-0 font-weight-400">
                        Interested in connecting - feel free to reach out!
                      </p>
                    </Col>
                    <Col xs="12" sm="auto">
                      <a href="mailto:hey@baileygribben.com" target="_blank">
                        <Button
                          block
                          color="white"
                          className="text-primary px-3 py-2 mt-3 mt-sm-0"
                          style={{ fontSize: 18 }}
                        >
                          EMAIL ME
                        </Button>
                      </a>
                    </Col>
                  </Row>
                </div>
                <div></div>
              </div>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

export default IndexPage;
